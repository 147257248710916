import React, {useEffect, useState} from "react";
import {Route} from "react-router-dom";
import gsap from "gsap";
import "./styles/App.scss";
import Header from "./components/header";



//pages
import Home from "./pages/home";
import CaseItems from "./pages/caseItems";
import Post from "./pages/post";

//routes
const routes = [
    {path: '/', name: 'Home', Component: Home},
    {path: '/caseItems', name: 'caseItems', Component: CaseItems},
    {path: '/post/:id', name: 'post', Component: Post},
]

function App() {
    useEffect(() => {
        //Inner height of window for mobile
        let vh = window.innerHeight * .01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);

        // prevent flashing
        gsap.to('body', 0, { css: { visibility: "visible" } });
    });





  return (
    <>
      <Header/>
      <div className="App">
          {routes.map(({path, Component}) => (
              <Route key={path} exact path={path}>
                  <Component />
              </Route>
          ))}
      </div>
    </>
  );
}

export default App;

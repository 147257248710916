import React, {useEffect, useState} from 'react';
import {CardContent, CardMedia, Container, Grid, IconButton, Link} from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useParams} from "react-router";

const Post = (props) => {
    let { id } = useParams();
    useEffect(() => {
        fetchApi();
    }, []);

    const [data, setData] = useState({ entries: [] });

// get al the posts
    async function fetchApi() {
        const response = await fetch(
            'https://admin.iamdavy.nl/api/collections/get/Post?token=fb920c145121b159ea15ff104afd3d',
            {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    filter: { publish: true, _id: id }
                }),
            }
        );
        const data = await response.json();
        setData(data);
        console.log(data)
    }

    //WYSIWYG
    const renderHTML = (rawHTML) =>
        React.createElement('div', {
            dangerouslySetInnerHTML: { __html: rawHTML },
        });

    //Render tags and separate them with ,
    const renderTags = (tags) => (tags ? tags.join(', ') : '');


    return (
        <>
            <div className="postHeading"></div>

            {data.entries.map((post) => (
                <div>
                    {/*<section className="postSection">*/}
                    {/*    <Container className="postContainer" md={10}>*/}
                    {/*        <Grid container>*/}

                    {/*            <Grid item>*/}
                    {/*                <div className="postImage">*/}
                    {/*                    <div className="imageBox">*/}
                    {/*                        <CardMedia className="cover">*/}
                    {/*                            <img src={post.Image}/>*/}
                    {/*                        </CardMedia>*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*            </Grid>*/}
                    {/*            <Grid item className="postMeta">*/}
                    {/*                <div className="postTitle">*/}
                    {/*                    <h7>{post.Title}</h7>*/}
                    {/*                </div>*/}
                    {/*                <div className="postTags">*/}
                    {/*                    {renderTags(post.Tags)}*/}
                    {/*                </div>*/}
                    {/*            </Grid>*/}
                    {/*        </Grid>*/}
                    {/*    </Container>*/}
                    {/*</section>*/}

                    <section className="textSection">

                        <Container className="textContainer" md={10}>

                                <IconButton className="backButton" aria-label="Terug naar overzicht" href="/caseItems">

                                    <ArrowBackIcon/>
                                    Terug naar overzicht

                                </IconButton>


                            <div className="opener">
                                <div className="case-block">
                                    {renderHTML(post.Intro)}
                                </div>
                            </div>
                            <div className="challenge">
                                <div className="case-block">
                                    {renderHTML(post.Text)}
                                </div>
                            </div><div className="conclusion">
                            <div className="case-block">
                                {renderHTML(post.Conclusion)}
                            </div>
                        </div>
                            <div style={{display: "flex", justifyContent: "space-between", marginBottom: "15px", marginTop: "15px"}}>
                                <Link style={{textDecoration: "none"}} href={`/post/${post.Prev}`}>VORIGE</Link>
                                <Link style={{textDecoration: "none"}} href={`/post/${post.Next}`}>VOLGENDE</Link>
                            </div>
                        </Container>
                    </section>
                </div>
            ))}
        </>
    );
}

export default Post;
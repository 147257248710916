import React, {useEffect, useState} from 'react';
import Pid from "../components/pid";
import InspirationFase from "../components/inspiration";
import IdeationFase from "../components/ideation";
import ImplementationFase from "../components/implementation";



const CaseItems = () => {
    return (
        <>
            <div className="heading"/>

            <Pid></Pid>

            {/*INSPIRATION FASE*/}
            <div className="behindText">
                Inspiration fase
            </div>
                <InspirationFase></InspirationFase>


            {/*IDEATION FASE*/}
            <div className="behindText">
                Ideation fase
            </div>
                <IdeationFase></IdeationFase>


            {/*IMPLEMENTATION FASE*/}
            <div className="behindText">
                Implementation fase
            </div>
                <ImplementationFase></ImplementationFase>
        </>
    )
}

export default CaseItems;

import React from 'react';
import { ReactComponent as RightArrow } from '../assets/arrow-right.svg';

const banner = () => {
    return (
        <section className="main">
            <div className="container">
                <div className="row">
                    <h2>
                        <div className="line">
                            <span>Afstudeerstage TenderGuide</span>
                        </div>
                        <div className="line">
                            <span>februari - juni</span>
                        </div>
                    </h2>
                    <div className="btn-row">
                        <a href="https://admin.iamdavy.nl/storage/uploads/2021/06/14/leeswijzer_uid_60c742b43e89e.pdf" target="_blank">
                            Leeswijzer
                            <RightArrow/>
                        </a>
                    </div>
                    <div className="btn-row" style={{marginTop: "10px"}}>
                        <a href="https://ux.research.tender.guide/post/608fc1a8646438612b000029">
                            Projectdocument
                            <RightArrow/>
                        </a>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default banner;

import React from 'react';
import {Link} from "@material-ui/core";

const caseStudies = [
    {
        id: 1,
        img: "inspiration"
    },
    {
        id: 2,
        img: "ideation"
    },
    {
        id: 3,
        img: "implementation"
    }
];


const Cases = () => {
    return (
        <section className="cases">
            <div className="container-fluid">
                <div className="row">
                    {caseStudies.map((caseItem) => (
                        <Link href="caseItems" style={{textDecoration: "none"}}>
                            <div className="case" key={caseItem.id}>
                                    <div className="case-details">
                                             <span className="span">View work</span>
                                    </div>

                                    <div className="case-image">
                                        <img src={`${caseItem.img}.svg`} />
                                    </div>
                            </div>
                        </Link>
                    ))}
                </div>
            </div>
        </section>

    );
}

export default Cases;

import React, {useEffect, useState} from 'react';
import {Button, Card, CardContent, CardMedia, Grid, Link, Typography} from '@material-ui/core';


const IdeationFase = (props) => {
    useEffect(() => {
        fetchApi();
    }, []);

    const [data, setData] = useState({ entries: [] });

// get al the posts
    async function fetchApi() {
        const response = await fetch(
            'https://admin.iamdavy.nl/api/collections/get/Post?token=fb920c145121b159ea15ff104afd3d',
            {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    filter: { publish: true, Category: "Ideation" }
                }),
            }
        );
        const data = await response.json();
        setData(data);
    }

    //Render tags and separate them with ,
    const renderTags = (tags) => (tags ? tags.join(', ') : '');

    return (

        <>
            {data.entries.map((post) => (
                <section className="mainList">
                    <Grid container className="gridContainer">
                        <Grid item md={3}/>
                        <Grid item md={8}>
                            <Link style={{textDecoration: "none"}} href={`/post/${post._id}`}>
                                <Card className="root blue">
                                    <Grid container>
                                        <Grid item md={4}>
                                            <div className="imageBox">
                                                <CardMedia className="cover">
                                                    <img className="image" src={post.Image} />
                                                </CardMedia>
                                            </div>
                                        </Grid>
                                        <Grid item md={8} style={{display: "flex", alignItems: "center"}}>
                                            <div className="details">
                                                <CardContent className="cardContent">
                                                    <h3>
                                                        {post.Title}
                                                    </h3>

                                                    <h6 style={{fontStyle: "italic"}}>
                                                        {renderTags(post.Tags)}
                                                    </h6>

                                                    <p  style={{paddingTop: "10px", width: "90%"}}>
                                                        {post.Excerpt}
                                                    </p>
                                                </CardContent>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Link>
                        </Grid>
                    </Grid>
                </section>
            ))}
        </>
    );
};

export default IdeationFase;
